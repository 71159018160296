import React from "react";
import useFetch from '../../hooks/useFetch';
import TimeString from "../utilities/TimeString";
import { RouteComponentProps } from "react-router-dom";
import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";
import { IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface Entry {
  typeHandle: string;
  id: number;
  startTime: string;
  endTime: string;
  title: string;
  startDate?: string;
  children: Entry[];
  registration: Registration[];
  exhibitHall: ExhibitHall[];
}

interface Registration {
  id: string;
}

interface ExhibitHall {
  id: string;
}

interface MatchParams {
  entryId: string;
  eventId: string;
}

interface Props extends RouteComponentProps<MatchParams> {}

const getEntryLink = (entry: Entry, eventId: string, registrationId: string, exhibitHallId: string): string => {
  switch (entry.typeHandle) {
    case "activity":
      return `/events/${eventId}/details/activity/${entry.id}`;
    case "exhibitHall":
      return `/events/${eventId}/details/details/${exhibitHallId}`;
    case "registration":
      return `/events/${eventId}/details/details/${registrationId}`;
    case "businessWorship":
      return `/events/${eventId}/details/session/${entry.id}`;
    case "mealReception":
      return `/events/${eventId}/details/mealReception/${entry.id}`;
    default:
      return "";
  }
};

const Day: React.FC<Props> = ({ match }) => {
  const { entryId, eventId } = match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry?: Entry, registration?: Registration[], exhibitHall?: ExhibitHall[] }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-day?id=${entryId}`
  );

  const { entry } = data || {};
  const registration = entry?.registration || [];  // Extract from `entry`
  const exhibitHall = entry?.exhibitHall || [];    // Extract from `entry`

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  const day = entry?.startDate
    ? new Date(entry.startDate).toLocaleDateString("en-us", { year: "numeric", month: "long", day: "numeric" })
    : "";

  const shouldShowButton = (entry: Entry) => {
    if (entry.typeHandle === "registration" && !(registration && registration[0]?.id)) {
      return false;
    }

    if (entry.typeHandle === "exhibitHall" && !(exhibitHall && exhibitHall[0]?.id)) {
      return false;
    }

    return true;
  };

  // console.log("Data received:", data);
  console.log("Registration:", data?.registration);
  console.log("Exhibit Hall:", data?.exhibitHall);
  // console.log("Entry Children:", data?.entry?.children);
  console.log("Full API Response:", data);



  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {entry && (
          <>
            <Headline title={entry.title} icon="Calendar" tag={day} />
            {entry.children?.map((childEntry: Entry, index: number) => (
              <div key={index}>
                {shouldShowButton(childEntry) && (
                  <Button
                    to={getEntryLink(childEntry, eventId, registration?.[0]?.id || "", exhibitHall?.[0]?.id || "")}
                    tag={TimeString(childEntry.startTime, childEntry.endTime)}
                    text={childEntry.title}
                  />
                )}
              </div>
            ))}
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default Day;
