import React from "react";
import { RouteComponentProps } from "react-router-dom";
import useFetch from "../../hooks/useFetch";
import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface MatchParams {
  eventId: string;
}

interface EntryChild {
  id: string;
  title: string;
  typeHandle: string;
  startDate: string;
}

interface Entry {
  id: string;
  title: string;
  children: EntryChild[];
}

interface Props extends RouteComponentProps<MatchParams> {}

export default function  Schedule({ match }: Props)  {
  const { eventId } = match.params;
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );


  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };
  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data && (
          <>
            <Headline title="Schedule" icon="ListUl" />
            {data.entry.children.map((entry: any) => {
              if (entry.typeHandle === "day") {
                let day = new Date(entry.startDate).toLocaleDateString("en-us", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                });

                return (
                  <div key={entry.id}>

                    <Button
                      to={"/events/" +
                        eventId + "/details/day/" +
                        entry.id}
                      tag={day}
                      text={entry.title}
                    />

                  </div>
                );
              }
              return "";
            })}
          </>
        )}

      </ContentWrapper>

    </IonPage>

  );
};
