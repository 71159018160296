import React from "react";
import { useParams } from "react-router-dom";
import { IonPage } from "@ionic/react";
import useFetch from "../../hooks/useFetch";
import Button from "../atoms/Button";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";
import EventHeader from "./EventHeader";
import * as icons from "../icons";

interface MatchParams {
  entryId?: string;
  eventId: string;
}

interface ProfilePhoto {
  url?: string;
}

interface Person {
  id: string;
  fullName: string;
  title: string;
  profilePhoto: ProfilePhoto;
}

interface Entry {
  id: string;
  title: string;
  icon?: keyof typeof icons;
  personalities?: Person[];
}

const PersonalityGroup: React.FC = () => {
  const { eventId } = useParams<MatchParams>();
  const { entryId } = useParams<MatchParams>();
  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-personality-group?id=${entryId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  if (data) console.log(data)

  return (
    <IonPage>
      <EventHeader eventId={eventId} />


      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        {data?.entry && (
          <>
            <Headline title={data.entry.title} icon={data.entry.icon} />
            {data.entry.personalities?.map((person: Person) => (
              <Button
                key={person.id}
                to={`/events/${eventId}/details/person/${person.id}`}
                image={{ srcUrl: person.profilePhoto?.url || "", alt: person.fullName }}
                text={person.title}
                fallback="UserSolid"
              />
            ))}
          </>
        )}
      </ContentWrapper>
    </IonPage>
  );
};

export default PersonalityGroup;
