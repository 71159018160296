import React from "react";
import useFetch from "../../hooks/useFetch";
import Headline from "../atoms/Headline";
import ContentWrapper from "../molecules/ContentWrapper";
import Subhead from "../atoms/Subhead";
import DateString from "../utilities/DateString";
import timeString from "../utilities/TimeString";
import Button from "../atoms/Button";
import { useParams } from "react-router-dom";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface MatchParams {
  eventId: string;
}

interface Entry {
  id: string;
  workshops?: Workshop[];
}

interface Workshop {
  id: string;
  title: string;
  startDate: string;
  startTime: string;
  endTime: string;
  shortname: string;
}


const Workshops: React.FC = () => {
  // Use the useParams hook to get the eventId from the URL
  const { eventId } = useParams<MatchParams>();

  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-event?id=${eventId}`
  );

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  let currentTime: string = "";
  let showTime: boolean = false;

  return (
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>

        <Headline title="Workshops" icon="ScreenUsers" />
        {data?.entry.workshops?.map((workshop: Workshop) => {

          let workshopTime = workshop.startDate + workshop.startTime;

          if (currentTime !== workshopTime) {
            currentTime = workshopTime;
            showTime = true    ;
          } else {
            showTime = false;
          }

          return (
            <div key={workshop.id}>
              {showTime && (
                <>
                  <span className="block h-6"></span>
                  <Subhead
                    title={DateString(workshop.startDate)}
                    tag={timeString(workshop.startTime, workshop.endTime)}
                  />
                </>
              )}
              <Button
                to={`/events/${eventId}/details/workshop/${workshop.id}`}
                text={workshop.title}
                tag={workshop.shortname}
              />
            </div>
          );
        })}
      </ContentWrapper>
    </IonPage>

  );
};
export default Workshops;
