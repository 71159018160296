import {IonRouterOutlet} from "@ionic/react";
import {Route} from "react-router-dom";
import Events from "./Events";
import React from "react";
import EventLanding from "./EventLanding";
import Schedule from "./Schedule";
import Meals from "./Meals";
import Speakers from "./Speakers";
import Workshops from "./Workshops";
import Worship from "./Worship";
import Activity from "./Activity";
import Day from "./Day";
import Meal from "./Meal";
import Person from "./Person";
import Session from "./Session";
import Workshop from "./Workshop";
import Details from "./Details";
import PersonalityGroup from "./PersonalityGroup";

export default function EventsRouter() {
  return(
    <IonRouterOutlet>
      <Route exact path="/events/" component={Events} />
      <Route exact path="/events/:eventId" component={EventLanding} />
      <Route exact path="/events/:eventId/details/schedule" component={Schedule} />
      <Route exact path="/events/:eventId/details/meals" component={Meals} />
      <Route exact path="/events/:eventId/details/speakers" component={Speakers} />
      <Route exact path="/events/:eventId/details/workshops" component={Workshops} />
      <Route exact path="/events/:eventId/details/worship" component={Worship} />
      <Route
        exact
        path="/events/:eventId/details/activity/:entryId"
        render={(props) => <Activity {...props} key={props.match.params.entryId} />}
      />
      <Route
        exact
        path="/events/:eventId/details/day/:entryId"
        render={(props) => <Day {...props} key={props.match.params.entryId} />}
      />
      <Route
        exact
        path="/events/:eventId/details/mealReception/:entryId"
        render={(props) => <Meal {...props} key={props.match.params.entryId} />}
      />
      <Route
        exact
        path="/events/:eventId/details/person/:entryId"
        render={(props) => <Person {...props} key={props.match.params.entryId} />}
      />
        <Route
          exact
          path="/events/:eventId/details/personalityGroup/:entryId"
          render={(props) => <PersonalityGroup {...props} key={props.match.params.entryId} />}
        />
      <Route
        exact
        path="/events/:eventId/details/session/:entryId"
        render={(props) => <Session {...props} key={props.match.params.entryId} />}
      />
      <Route
        exact
        path="/events/:eventId/details/workshop/:entryId"
        render={(props) => <Workshop {...props} key={props.match.params.entryId} />}
      />
      <Route
        exact
        path="/events/:eventId/details/details/:entryId"
        render={(props) => <Details {...props} key={props.match.params.entryId} />}
      />
    </IonRouterOutlet>
  )
}