import React from "react";
import useFetch from "../../hooks/useFetch";
import Headline from "../atoms/Headline";
import TextBlock from "../atoms/TextBlock";
import Button from "../atoms/Button";
import Subhead from "../atoms/Subhead";
import ContentWrapper from "../molecules/ContentWrapper";
import timeString from "../utilities/TimeString";
import dateString from "../utilities/DateString";
import TransparentBg from "../molecules/TransparentBg";
import * as icons from "../icons";
import {IonPage} from "@ionic/react";
import EventHeader from "./EventHeader";

interface AgendaItem {
  label: string;
  presenter: string;
  about?: string;
  id: string;
  link: {  title: string;
    id: string;
    profilePhoto: { url: string | undefined;};};
}

interface ParentEntry {
  startDate?: string;
}

interface Entry {
  id: string;
  title: string;
  icon?: keyof typeof icons;
  descriptionRichText?: string;
  startTime?: string;
  endTime?: string;
  parent: ParentEntry;
  agenda: AgendaItem[];
}

const Session = ({...props}) => {

  const { entryId, eventId } = props.match.params

  const { data, loading, error, refetch, isConnected } = useFetch<{ entry: Entry }>(
    `${process.env.REACT_APP_API_DOMAIN}/api/v2/app-session?id=${entryId}`
  );

  let html = {__html :  data?.entry?.descriptionRichText ?? ""}

  const handleRefresh = async (event: CustomEvent) => {
    await refetch();
    event.detail.complete();
  };

  return(
    <IonPage>
      <EventHeader eventId={eventId}/>
      <ContentWrapper handleRefresh={handleRefresh} isConnected={isConnected} error={error} loading={loading}>
        { data &&
            <>
                <Headline
                    icon={data.entry.icon}
                    title={data.entry.title}
                />

                <Subhead
                    title={dateString(data.entry.parent?.startDate ?? "", "short")}
                    tag={timeString(data.entry.startTime ?? "", data.entry.endTime ?? "")}
                />

                <TextBlock
                    text={html}
                />

                <div className="-mx-8">
                    <div className="flex overflow-x-auto snap-x px-8 swiper-scrollbar-drag">
                        <div className="w-6 flex-shrink-0 h-420 snap-start"></div>
                      {data.entry.agenda.map((item: AgendaItem) => {
                        if (item?.link?.title && item.link?.id && item.link?.profilePhoto?.url) {
                          return (
                            <div key={item.id} className="w-40 pl-4 flex-shrink-0 snap-start">
                              <Button
                                text={item.link.title}
                                small={true}
                                image={{
                                  srcUrl: item.link.profilePhoto.url,
                                  alt: item.link.title,
                                }}
                                to={`/events/${eventId}/details/person/${item.link.id}`}
                              />
                            </div>
                          );
                        }
                        return null;
                      })}
                    </div>


                </div>

              {data.entry.agenda.length !== 0 && (

                <TransparentBg>
                  {data.entry.agenda.map((item: AgendaItem) => {
                    return (
                      <div key={item.id}>
                        <div className="text-sm mb-6">
                          <h2 className="text-lg font-semibold tracking-wide">{item.label}</h2>
                          <p>{item.presenter}</p>
                          {item.about && <p>{item.about}</p>}
                        </div>
                      </div>
                    );
                  })}
                </TransparentBg>

              )}
            </>
        }
      </ContentWrapper>
    </IonPage>

  )
}

export default Session
